import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  Switch,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditText from "../../components/EditText";
import { Loader, showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import { useAddCommunityMutation } from "../../services/community";
import { isString } from "../../utils/validations";

import {
  useLazyGetAllProductsQuery,
  useAddProductMutation,
  useLazyGetProductByIdQuery,
  useChangeProductStatusMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useGetcheckUtcMutation,
} from "../../services/product";
import useAuth from "../../hooks/useAuth";
import { useLazyGetCategoryQuery } from "../../services/category";
import EditTextDescription from "../../components/EditTextDescription";

const AddProduct = () => {
  const [displayEditText, setDisplayEditText] = useState(true);
  const [displayEditText2, setDisplayEditText2] = useState(true);
  const navigate = useNavigate();
  const [updateProduct] = useUpdateProductMutation();
  const [checkUtcApi] = useGetcheckUtcMutation();
  const [error1, setError1] = useState<boolean>(false);
  const [error2, setError2] = useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [description, setDescription] = useState<string>("");
  const [errorIngredients, setErrorIngredients] = useState(false);
  const [descriptionHTML, setDescriptionHTML] = useState<any>("");
  console.log("htm", descriptionHTML);
  console.log("description", description);
  const [ingredients, setIngredients] = useState<string>("");
  console.log("ingredients", ingredients);
  const [planType, setPlanType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState(false);
  const [error12, setError12] = useState(false);
  console.log(error, "op");
  const [fileName, setFileName] = useState<string>("");
  const { id } = useParams();
  console.log("whxuwhiuwhx", id);
  type ImageType = string | { link: string | null };
  const [getProductData] = useLazyGetProductByIdQuery();
  const [productDetails, setProductDetails] = useState<any>();
  const [newRole, setNewRole] = useState("");
  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };
  const [images, setImages] = useState<ImageType[]>([]);
  console.log("icwbir3bwicrwbrc", images);
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [getCategoryList] = useLazyGetCategoryQuery();
  const getCategory = async () => {
    try {
      const response = await getCategoryList({
        page: null,
        limit: 80,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        const filterdeData = response?.data?.category?.filter(
          (it: { isBlocked: boolean }) => it?.isBlocked == false
        );
        setCategoryList(filterdeData || []);
      } else {
        setCategoryList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const checkUtcNo = async () => {
    try {
      if (!formik?.values?.barcode) {
        showError("Please enter Upc number to check");
        return;
      }
      const response = await checkUtcApi({
        utcId: formik?.values?.barcode,
      }).unwrap();
      // dn
      if (response?.statusCode === 200) {
        if (!response?.data)
          showToast("Upc Number doesn't exist in database, You are good to go");
        else {
          showError("This Upc Number already exist");
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getCategory();
  }, []);
  console.log("userdata", userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Products");
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  const [nutrition, setNutrition] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rolesData, setRolesData] = useState<
    { nutrition: string; quantity: string }[]
  >([]);
  console.log("nutri", rolesData);
  const [CommunityType, setCommunityType] = useState("");

  useEffect(() => {
    checkPermission();
  }, [userData]);
  const getProductDetailById = async (id: any) => {
    setDisplayEditText(false);
    setDisplayEditText2(false);
    try {
      const response = await getProductData({ subId: id }).unwrap();
      if (response?.statusCode === 200) {
        const {
          name,
          sku,
          barcode,
          ingredients,
          nutritions,
          uploads,
          html,
          categoryId,
        } = response?.data;
        setProductDetails(response?.data);
        formik.setFieldValue("title", name);
        formik.setFieldValue("sku", sku);
        formik.setFieldValue("barcode", barcode);
        formik.setFieldValue("categoryId", categoryId?._id);
        setSubscriptionType(categoryId?._id);
        setIngredients(html);
        setDescription(response?.data?.description);
        console.log(response?.data?.description, "RESSS");
        setImages(uploads.map((upload: any) => upload.link));

        const rolesData = nutritions.map((nutrition: any) => ({
          nutrition: nutrition.nutrient,
          quantity: nutrition.value,
        }));
        setRolesData(rolesData);

        // Set ingredients and description from the response
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.data?.message || "");
    }

    setDisplayEditText(true);
    setDisplayEditText2(true);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      sku: "",
      barcode: "",
      description: "",
      ingredients: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Product Name is required")
        .min(2, "Minimum 2 characters are required"),
      sku: Yup.string()
        .required("SKU is required")
        .matches(/^[a-zA-Z0-9]+$/, "SKU must be alphanumeric")
        .max(40, "Maximum 40 characters are allowed")
        .min(2, "Minimum 2 characters are required"),
      barcode: Yup.string()
        .required("Upc is required")
        .matches(/^\d+$/, "Upc must be a numerical value")
        .max(40, "Maximum 40 numerical digits are allowed")
        .min(2, "Minimum 2 numerical digits are required"),
      // Remove nutrition and quantity validation
    }),

    onSubmit: async () => {
      if (subscriptionType === "") {
        return;
      }
      formik.setSubmitting(true);

      const nutritionQuantityPairs = rolesData.map((role) => ({
        nutrient: role.nutrition,
        value: role.quantity,
      }));

      const stripHtmlTags = (html: string) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
      };

      const ingredientstrim = ingredients
        ?.split("\n")
        .map((ingredient) => stripHtmlTags(ingredient.trim()))
        .filter((ingredient) => ingredient !== "");

      const body = {
        name: formik?.values?.title,
        sku: formik?.values?.sku,
        barcode: formik?.values?.barcode,
        description: description || "",
        ingredients: ingredientstrim,
        nutritions: rolesData.length > 0 ? nutritionQuantityPairs : [],
        html: ingredients,
        uploads: images?.map((image) => ({ link: image, type: 1 })),
        categoryId: subscriptionType,
      };

      console.log("9876567898765", body);
      if (ingredients.length < 9) {
        setErrorIngredients(true);
        return;
      }
      if (images.length == 0) {
        showError("Kindly upload atleast 1 image");
        return;
      }
      if (!id) {
        try {
          const response = await addProduct(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Product Added Successfully");
            formik.resetForm();
            navigate("/manage-product");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await updateProduct({ subsId: id, body }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Product Updated Successfully");
            formik.resetForm();
            navigate("/manage-product");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length) {
      const file = files[0];
      const res = await UploadMedia(file);
      if (res?.statusCode === 200 && res.data !== null) {
        setImages([...images, res.data]);
        // setError(false);
      } else {
        setError(true);
      }
    }
  };

  {
    images.map(
      (image, index) =>
        typeof image === "string" && (
          <div key={index} className="upload_image_preview">
            <CardMedia
              component="img"
              image={image}
              alt="photo"
              style={{ borderRadius: 0 }}
            />
            <CloseIcon onClick={() => removeImage(index)} />
          </div>
        )
    );
  }

  const removeImage = (index: number) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleAddRole = (event: React.FormEvent) => {
    event.preventDefault();

    // Check if either nutrition or quantity is provided
    if (nutrition.trim() !== "" || quantity.trim() !== "") {
      setRolesData([...rolesData, { nutrition, quantity }]);
      setNutrition("");
      setQuantity("");
    } else {
      // Optionally, you can show an error message or take another action
      // if both fields are empty, depending on your UI/UX requirements.
      // For example:
      // setError(true);
      // setErrorMessage("Either Nutrition or Quantity is required.");
    }
  };

  const handleDeleteRole = (index: number) => {
    const updatedRolesData = [...rolesData];
    updatedRolesData.splice(index, 1);
    setRolesData(updatedRolesData);
  };
  const handlePlanChange = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };
  useEffect(() => {
    if (id) {
      getProductDetailById(id);
    }
  }, [id]);

  console.log("DES", description);
  console.log("ING", ingredients);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
          <h1>{id ? "Edit Product" : "Add Product"}</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
              marginTop: "-55px",
            }}
          >
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-product");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  <Box className="upload_image">
                    <label htmlFor="icon-button-file">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        inputProps={{ accept: "image/png,image/jpeg" }}
                        onChange={handleImageUpload}
                      />
                      <Button component="span" className="upload_image_btn">
                        <img
                          style={{ borderRadius: 0 }}
                          src="/static/images/product1.png"
                          alt=""
                        />
                        <figure className="camera_btn">
                          <img src="/static/images/camera_icon.svg" alt="" />
                        </figure>
                      </Button>
                    </label>
                    {error && !images?.length && (
                      <h6 className="err_msg">This field is required</h6>
                    )}
                  </Box>
                  {images.map(
                    (image, index) =>
                      typeof image === "string" && (
                        <div key={index} className="upload_image_preview">
                          <CardMedia
                            component="img"
                            image={image}
                            alt="photo"
                            style={{ borderRadius: 0 }}
                          />
                          <CloseIcon onClick={() => removeImage(index)} />
                        </div>
                      )
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Typography className="custom_label">Product Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    // inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    fullWidth
                    placeholder="Title"
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <Typography className="custom_label">
                        UPC/Bar Code Number
                      </Typography>
                      <TextField
                        hiddenLabel
                        type="text"
                        name="barcode"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        fullWidth
                        placeholder="Code Number"
                        className="text_field"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.barcode}
                        error={
                          formik.touched.barcode &&
                          Boolean(formik.errors.barcode)
                        }
                        helperText={
                          formik.touched.barcode && formik.errors.barcode
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{ cursor: "pointer", color: "#007BFF" }}
                        onClick={() => checkUtcNo()}
                      >
                        Check UPC Number
                      </div>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: "black",
                          marginTop: 5,
                        }}
                      ></div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Typography className="custom_label">SKU</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="sku"
                    inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    fullWidth
                    placeholder="SKU"
                    className="text_field"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.sku}
                    error={formik.touched.sku && Boolean(formik.errors.sku)}
                    helperText={
                      formik.touched.sku &&
                      formik.errors.sku &&
                      formik.errors.sku
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    Category Type
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      sx={{ marginLeft: "-5px" }}
                      fullWidth
                      className="select_div2"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subscriptionType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={(e) => setSubscriptionType(e.target.value)}
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {categoryList?.length > 0 &&
                        categoryList?.map((data) => (
                          <MenuItem key={data._id} value={data._id}>
                            {data.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {error && subscriptionType == "" && (
                      <h6 className="err_msg">Please select Category Type</h6>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>

            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className="custom_label">
                  Enter Nutrition
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="nutrition"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Nutrition"
                  value={nutrition}
                  onChange={(event) => setNutrition(event.target.value)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className="custom_label">Enter Quantity</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="quantity"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Quantity"
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12} className="align-center">
                <div className="form_btn" style={{ marginTop: "22px" }}>
                  <Button
                    size="large"
                    onClick={handleAddRole}
                    className="btn btn_primary"
                  >
                    Add
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="mn_hdng" style={{ width: "10%" }}>
                        S.No.
                      </TableCell>
                      <TableCell className="mn_hdng">Nutrition</TableCell>
                      <TableCell className="mn_hdng">
                        Product Quantity
                      </TableCell>
                      <TableCell className="mn_hdng">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  {rolesData?.length ? (
                    rolesData?.map((row, i) => (
                      <TableBody key={i}>
                        <TableRow>
                          <TableCell className="mn_hdng">{i + 1}</TableCell>
                          <TableCell>{row.nutrition}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleDeleteRole(i)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody
                      sx={{
                        padding: "20px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <TableRow
                      // sx={{ padding: "20px", textAlign: "center" }}
                      >
                        <TableCell
                          colSpan={5}
                          sx={{ padding: "20px", textAlign: "center" }}
                        >
                          No data Added
                        </TableCell>{" "}
                        {/* No Roles Added */}
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="dialog_title"
                style={{ paddingTop: "20px" }}
              >
                Ingredients
              </Typography>
              <div style={{ width: "70%" }}>
                {displayEditText2 && (
                  <>
                    <EditText
                      content={ingredients}
                      setContent={setIngredients}
                    />
                    {ingredients.length < 9 ? (
                      <h6 className="err_msg">Ingredients is required</h6>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                className="dialog_title"
                style={{ paddingTop: "20px" }}
              >
                Description
              </Typography>
              <div style={{ width: "90%", height: "90%" }}>
                {displayEditText && (
                  <EditTextDescription
                    content={description}
                    setContent={setDescription}
                  />
                )}
              </div>
            </Grid>

            <div className="form_btn">
              <Button
                size="large"
                type="submit"
                className="btn btn_primary"
                onClick={() => setError(true)}
              >
                Save
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddProduct;
