import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  useLazyGetAllProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useLazyGetAllCategoriesForFilterQuery,
  useLazyGetProductCategoryWiseByIdQuery
} from "../../services/product";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { Loader, showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import { useLazyGetCategoryQuery } from "../../services/category";
const ManageProducts = () => {
  const navigate = useNavigate();
 const[showProductByCategory]= useLazyGetProductCategoryWiseByIdQuery();
  const [getAllProducts, { isLoading }] = useLazyGetAllProductsQuery();
  const [productStatus] = useUpdateProductMutation();
  const [deleteProduct] = useDeleteProductMutation();
  const { id } = useParams();
  const [categoryType, setCategoryType] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allProducts, setallProducts] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  console.log("totalcount", totalCount);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [getCategoryList] = useLazyGetAllCategoriesForFilterQuery();
  const [selectedId, setSelectedId] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
  const userData = useAuth();

  let totalPages = Math.ceil(totalCount / 10);
  // const totalPages = Math.ceil(totalCount / 10);
 
  console.log("userdata", userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Products");
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  useEffect(() => {
    checkPermission();
  }, [userData]);

  console.log("hide per", hidePermission);
  const getProducts = async () => {
    try {
      const response = await getAllProducts({
        query: debouncedSearchTerm.trim(),
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log(response?.data?.product);
        setallProducts(response?.data?.product || []);
        setTotalCount(response?.data?.totalproduct);
      } else {
        setallProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const getProductsByCategory = async (categoryId: string) => {
    try {
      const response = await showProductByCategory({ subId: categoryId }).unwrap();
      if (response?.statusCode === 200) {
        setallProducts(response?.data?.product || []);
        setTotalCount(response?.data?.totalproduct);
      } else {
        setallProducts([]);
      }
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };
  const updateStatus = async (id: any, isBlocked: any) => {
    const body = {
      isBlocked: !isBlocked,
    };
    try {
      const response = await productStatus({ subsId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Product Status updated successfully");

        // Update the isBlocked property for the product in allProducts
        const updatedProducts = allProducts.map((product: any) => {
          if (product._id === id) {
            return { ...product, isBlocked: !isBlocked };
          }
          return product;
        });

        // Update the state with the new products array
        setallProducts(updatedProducts);
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDelete = async (id: string) => {
    try {
      console.log("weucuewhe", id);
      const response = await deleteProduct({ userId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Product deleted successfully");
        getProducts();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  const [categoryList, setCategoryList] = useState<any[]>(
    []
  );
 
  const getCategory = async () => {
    try {
      const response = await getCategoryList({}).unwrap();
      if (response?.statusCode === 200) {
        setCategoryList(response?.data?.category || []);
      } else {
        setCategoryList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getCategory();

  }, [])
  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), "yyyy-MM-dd");
    const time = format(new Date(dateString), "HH:mm:ss");
    return ` ${date}, Time: ${time}`;
  };
  // useEffect(() => {
  //   getProducts();
  // }, [debouncedSearchTerm, page]);
  useEffect(() => {
    if (subscriptionType) {
      getProductsByCategory(subscriptionType);
    } else {
      getProducts();
    }
  }, [subscriptionType, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_layout">
        <div className="dashboard">
          <h3>Manage Products</h3>
        </div>
        <Card className="cards">
          <Loader isLoad={isLoading} />
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: {
                target: { value: React.SetStateAction<string> };
              }) => {
                setSearchTerm(val.target.value);
              }}
            />
            <Box className="cards_header_right">
            <Button
                  className="btn btn_primary"
                 
                >
                  Total Products: {totalCount}
                </Button>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                  {/* <Typography className="custom_label">
                    Category Type

                  </Typography> */}
                  
                  <FormControl sx={{ width: "100%" }}>
                    
                    <Select
                      sx={{ width:"100%"}}
                      fullWidth
                      className="select_div2"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subscriptionType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={(e) => setSubscriptionType(e.target.value)}
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {categoryList.map((data) => (
                        <MenuItem key={data._id} value={data._id} >
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                   
                    {/* {error && subscriptionType=="" && (
                      <h6 className="err_msg">Please select Category Type</h6>
                    )} */}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
              {userData?.role === "ADMIN" || hidePermission?.isEdit ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-product/add")}
                >
                  Add Product
                </Button>
              ) : (
                <></>
              )}
              </Grid>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "2%" }}>
                    S.No
                  </TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Product Name</TableCell>
                  <TableCell align="center">UPC/Bar Code Number</TableCell>
                  <TableCell align="center">SKU</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Date & Time</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allProducts.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No product found
                    </TableCell>
                  </TableRow>
                ) : (
                  allProducts?.map((row: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell align="center" style={{ width: "2%" }}>
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell style={{ width: "6%" }}>
                        <figure className="user_img">
                          <img
                            style={{ borderRadius: 0 }}
                            src={
                              row?.uploads[0]?.link ||
                              "/static/images/product1.png"
                            }
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "30%",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell>{row?.barcode}</TableCell>
                      <TableCell>{row?.sku}</TableCell>
                      <TableCell>
                        {row?.categoryId?.name ? row?.categoryId?.name : "-"}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {formatDateTime(row?.createdAt) || "-"}
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, row?.isBlocked)
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-product/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {userData?.role === "ADMIN" ||
                          hidePermission?.isEdit ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-product/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          ) : null}
                          {userData?.role === "ADMIN" ||
                          hidePermission?.isDelete ? (
                            <IconButton
                              onClick={() => {
                                setSelectedId(row?._id);
                                setOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={allProducts}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Product"
      />
    </MainContainer>
  );
};

export default ManageProducts;
