//export const API_URL =
// "https://allergyproofapi.appgrowthcompany.com/api/v1/admin/";
//development

export const API_URL = "https://api.allergyproofinc.com/api/v1/admin/" // staging

export const UPLOAD_URL =
  "https://api.allergyproofinc.com/api/v1/user/upload"; // for upload

export const END_POINTS = {
  //onboarding
  login: "login",
  getUser: "profile",
  forgetPassword: "forget/password",
  verifyOtp: "forget/verify",
  updateProfile: "profile",
  resetPassword: "reset/password",
  changePassword: "change/password",
  hi: "hi",
  //main
  allergy: "allergy",
  request: "allergy/request",
  dashboard: "dashboard",
  user: "user",
  cms: "cms",
  exportCsv: "export/user",
  contactSupport: "contactSupport",
  replyUser: "replyUser",
  subscription: "subscription",
  notification: "notification",
  logout: "logout",
  community: "community",
  getAllPost: "getAllPost",
  comment: "comment",
  post: "post",
  ///product 
  product: "product",
  //subAdmin
  subAdmin: "subAdmin",

  //category
  category: "category",
  checkProduct: "checkProduct"
};
