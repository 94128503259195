//import { url } from "inspector";
import { END_POINTS } from "../constants/url";
import emptySplitApi from "../utils/rtk";
 
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllCategoryResponse = {
    Category: any;
    count: number;
  };
type CategoryResponse=any;
type GetTokenParams = {
    // limit?: number;
    // page?: number;
    // query?: string;
  };

type EncryptedBody = {
  hash: string;
  sek: string;
} | null
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getCategory:builder.query<
CommonResponseType & {data:any},
    any>({
      
        query:({page,query,limit})=>{
        // query:()=>{
          let url = END_POINTS.category;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        if (limit) {
          queryParams.push(`limit=${limit}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getCategoryById:builder.query<CommonResponseType & {data:CategoryResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.category}/${id}`,
        method:"GET",
    })
}),
editCategoryById: builder.mutation<
      CommonResponseType & { data: CategoryResponse },
      { id: string|undefined; body: any }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "PUT",
        body,
      }),
    }),

addCategory: builder.mutation<
CommonResponseType & { data: CategoryResponse },
any
>({
query: (body) => ({
  url: END_POINTS.category,
  method: "POST",
  body,
})
}),

deleteCategoryById:builder.mutation<
CommonResponseType & {data:CategoryResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.category}/${id}`,
        method:'DELETE'
    })
}),
})
})

export const{
 useLazyGetCategoryQuery,
 useLazyGetCategoryByIdQuery,
 useAddCategoryMutation,
 useDeleteCategoryByIdMutation,
 useEditCategoryByIdMutation,
}=userApi;