import { END_POINTS } from "../constants/url";


import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
  limit: any
};

type UpdateParams = {
  subsId: string | undefined;
  body: {
    name: string;
    price: string;
    description: string;
    features: any;
    planType: number;
    subscriptionType: number;
    isBlocked?: boolean;
  };
};

export const productApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query<
      CommonResponseType & {
        data: {
          count?: number;
          totalproduct?: any;
          data?: any;
          product?: any
        };
      },
      GetUserParams
    >({
      query: ({ page, query, limit }) => ({
        url: END_POINTS.product + "?page=" + page + "&search=" + query + "&limit" + limit,
        method: "GET",
      }),
    }),
    getAllCategoriesForFilter: builder.query<
      CommonResponseType & {
        data: any;
      },
      {}
    >({
      query: ({ }) => ({
        url: `${END_POINTS.category}?type=ALL`,
        method: "GET",
      }),
    }),
    // ljzowhu1s
    getProductCategoryWiseById: builder.query<
      CommonResponseType & { data: any },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.product}?categoryId=${subId}`,
        method: "GET",
      }),
    }),
    // qushxiuqgxiqewgxyc
    addProduct: builder.mutation<
      { message: string; statusCode: number; data: any },
      any
    >({
      query: (body: any) => ({
        url: END_POINTS.product,
        method: "POST",
        body,
      }),
    }),

    getProductById: builder.query<
      CommonResponseType & { data: any },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.product}/${subId}`,
        method: "GET",
      }),
    }),

    changeProductStatus: builder.mutation<
      { message: string; statusCode: number },
      { subsId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.product}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteProduct: builder.mutation<
      CommonResponseType,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.product}/${userId}`,
        method: "DELETE",
      }),
    }),

    GetcheckUtc: builder.mutation<
      CommonResponseType,
      { utcId: string }
    >({
      query: ({ utcId }) => ({
        url: `${END_POINTS.checkProduct}/${utcId}`,
        method: "GET",
      }),
    }),
    updateProduct: builder.mutation<
      CommonResponseType & { data: any },
      any
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.product}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllProductsQuery,
  useAddProductMutation,
  useLazyGetProductByIdQuery,
  useChangeProductStatusMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useLazyGetAllCategoriesForFilterQuery,
  useLazyGetProductCategoryWiseByIdQuery,
  useGetcheckUtcMutation
} = productApi;
